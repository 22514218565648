<!-- 下载安装 -->
<template>
    <div class="download">
        <div class="banner">
            <h2>平正云科技<span>应用</span>下载</h2>
        </div>
        <!-- 面包屑 -->
        <div class="bread-crumb">
            <div class="crumb">
                <span>平正云科技</span>
                <img src="../../assets/customerCase/right@3x.png" alt="" />
            </div>
            <div class="crumb">
                <span>产品</span>
                <img src="../../assets/customerCase/right@3x.png" alt="" />
            </div>
            <div class="crumb">
                <span>下载安装</span>
                <!-- <img src="../../assets/customerCase/right@3x.png" alt="" /> -->
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="item" v-for="(item, index) in codeList" :key="index">
                    <div class="title">
                        <h4>{{ item.title }}</h4>
                        <span></span>
                    </div>
                    <div class="details">
                        <div class="andriod">
                            <img :src="item.img1" alt="" class="code" />
                            <div class="which">
                                <img :src="item.icon1" alt="" class="icon" />
                                <span>{{ item.name1 }}</span>
                            </div>
                            <p>{{ item.details1 }}</p>
                        </div>
                        <div class="ios">
                            <img :src="item.img2" alt="" class="code" />
                            <div class="which">
                                <img :src="item.icon2" alt="" class="icon" />
                                <span>{{ item.name2 }}</span>
                            </div>
                            <p>{{ item.details2 }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-mobile">
            <div class="container">
                <div class="item" v-for="(item, index) in codeList" :key="index">
                    <div class="title">
                        <h4>{{ item.title }}</h4>
                        <span></span>
                    </div>
                    <div class="details">
                        <div class="andriod">
                            <div class="which">
                                <img :src="item.icon1" alt="" class="icon" />
                                <span>{{ item.name1 }}</span>
                            </div>
                            <p @click="downLoad(index)">立即下载</p>
                        </div>
                        <div class="ios">
                            <div class="which">
                                <img :src="item.icon2" alt="" class="icon" />
                                <span>{{ item.name2 }}</span>
                            </div>
                            <p @click="codeClick(index)">获取二维码</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 弹窗扫码 -->
            <div class="code-modal" v-fixed @click="closeModal" v-if="modalCode">
                <div class="container">
                    <h2>{{ codeModal.title }}</h2>
                    <img :src="codeModal.img" alt="" />
                    <p>{{ codeModal.details }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            codeList: [
                {
                    title: "智慧作业平台",
                    img1: require("../../assets/download/code@3x.png"),
                    img2: require("../../assets/download/iOS@3x.png"),
                    icon1: require("../../assets/download/icon1.png"),
                    icon2: require("../../assets/download/icon2.png"),
                    name1: "安卓手机端",
                    name2: "iOS小程序",
                    details1: "安卓用户，手机扫一扫下载客户端",
                    details2: "iOS用户可微信扫码直接访问小程序",
                },
                {
                    title: "协同治理平台",
                    img1: require("../../assets/download/code@3x.png"),
                    img2: require("../../assets/download/ios2.jpg"),
                    icon1: require("../../assets/download/icon1.png"),
                    icon2: require("../../assets/download/icon2.png"),
                    name1: "安卓手机端",
                    name2: "iOS小程序",
                    details1: "安卓用户，手机扫一扫下载客户端",
                    details2: "iOS用户可微信扫码直接访问小程序",
                },
            ], //
            codeModal: {},
            modalCode: false, //弹窗默认不展示
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 弹窗打开
        codeClick(index) {
            // console.log(index, "index");
            this.modalCode = true;
            if (index == 0) {
                this.codeModal = {
                    title: "iOS小程序",
                    img: require("../../assets/download/iOS@3x.png"),
                    details: "用户可微信扫码直接访问小程序",
                };
            } else if (index == 1) {
                this.codeModal = {
                    title: "iOS小程序",
                    img: require("../../assets/download/ios2.jpg"),
                    details: "用户可微信扫码直接访问小程序",
                };
            }
        },
        // 弹窗关闭
        closeModal() {
            this.modalCode = false;
        },
        // 移动端下载
        downLoad(e) {
            if (e == 0) {
                window.location.href = "http://www.pingzhengyun.com/portal/__PZY__7F0F25B_1127183134.apk";
            }
        },
    },
    directives: {
        fixed: {
            // inserted 被绑定元素插入父节点时调用
            inserted() {
                let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                document.body.style.cssText += "position:fixed;width:100%;top:-" + scrollTop + "px;";
            },
            // unbind 指令与元素解绑时调用
            unbind() {
                let body = document.body;
                body.style.position = "";
                let top = body.style.top;
                document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
                body.style.top = "";
            },
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "download_PC.scss";
}
@media screen and (max-width: 750px) {
    @import "download_mobile.scss";
}
</style>
